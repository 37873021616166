import C from "../../components/ActionMenu.module.css";

import type { ReactNode } from "react";
import { GridList, GridListItem, Header, MenuTrigger } from "react-aria-components";
import { useNotificationCenter, type UseNotificationCenter } from "react-toastify/addons/use-notification-center";
import { WPopover } from "../../components/ActionMenu.tsx";
import { WButton } from "../../components/button/WButton.tsx";
import { MaskIcon } from "../../components/icon/MaskIcon.tsx";
import { cn } from "../../utils/classNames.ts";
import type { ToastifyData } from "./toast.tsx";

const typeIcons = {
    success: "jp-icon-checkmark color-success",
    error: "jp-wrong-icon color-error",
    info: undefined,
    warning: undefined,
    default: undefined,
};

export interface NotificationItemProps {
    type?: keyof typeof typeIcons;
    icon?: string | undefined;
    nc: UseNotificationCenter<ToastifyData>;
    id: number | string;
    children: ReactNode;
    fallbackText?: string;
}

export function NotificationItem({ type, icon, nc, id, children }: NotificationItemProps) {
    const iconElement = icon ? <MaskIcon className={cn(icon, "size-1rem")} /> : null;

    return (
        <GridListItem
            className={cn(C.ActionMenuItem, type && `Toastify__toast--${type}`, type === "success" && C.success)}
            key={id}
        >
            {iconElement && <div className="Toastify__toast-icon">{iconElement}</div>}
            <div className={C.title}>{children}</div>
            <WButton
                className={C.button}
                variant="ghost"
                size="xs"
                icon="jp-icon-close"
                ariaLabel="Remove"
                action={() => nc.remove(id)}
            />
        </GridListItem>
    );
}

export const NotificationButton = ({ hasUnread }: { hasUnread: boolean }) => (
    <div className={hasUnread ? cn(C.hasUnread, "text-primary") : undefined}>
        <WButton
            color="primary"
            variant="ghost"
            size="bar"
            icon="jp-bell-icon"
            ariaLabel="Notifications"
            action={undefined}
        />
    </div>
);

export function NotificationMenu() {
    const nc = useNotificationCenter<ToastifyData>();

    const notifications = nc.notifications.filter((n) => !n.data?.noNotification);

    const items = notifications.map((n) => {
        const { type, data, id, content } = n;
        return (
            <NotificationItem key={id} id={id} type={type} icon={data?.icon} nc={nc}>
                {content as ReactNode}
            </NotificationItem>
        );
    });

    const hasUnread = notifications.some((n) => !n.read);

    return (
        <MenuTrigger>
            <NotificationButton hasUnread={hasUnread} />

            <WPopover className={C.NotificationMenu} placement="bottom">
                <Header className={C.ActionMenuHeader}>
                    Notifications
                    {!!items.length && (
                        <WButton color="primary" variant="ghost" size="xs" action={() => nc.clear()}>
                            Clear all
                        </WButton>
                    )}
                </Header>

                <GridList className={C.Scrollable} selectionMode="none">
                    {items.length === 0 ? (
                        <GridListItem className={C.ActionMenuItem} isDisabled={true}>
                            You have no notifications.
                        </GridListItem>
                    ) : (
                        items
                    )}
                </GridList>
            </WPopover>
        </MenuTrigger>
    );
}

export default NotificationMenu;
